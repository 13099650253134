<template>
  <div class="csn-session-control">
    <div :class="classList.modalDialog">
      <div :class="classList.modalContent">
        <div :class="classList.modalHeader">
          <h5 :class="classList.modalTitle">
            {{ t('reality_check') }}
          </h5>
        </div>
        <Loader v-if="isGettingRealityCheck" />
        <div v-else :class="classList.modalBody">
          <p>{{ t('bet') }} {{ betStat }}</p>
          <p>{{ t('win') }} {{ winStat }}</p>
          <p>{{ t('question') }}</p>
          <div class="csn-session-control-button-container">
            <button
              class="btn casino-btn csn-session-control-confirm-btn"
              @click="handleSessionControlConfirm"
            >
              {{ t('confirm') }}
            </button>
            <button
              class="btn casino-btn csn-session-control-leave-btn"
              @click="handleSessionControlLeave"
            >
              {{ t('leave') }} {{ counter }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AuthApi } from '@/api'
import {
  Module,
  IS_LOGGED,
  Digit,
  SET_IS_SESSION_CONTROL_PENDING,
  EventType,
  EMPTY_STRING,
  SET_NEEDS_TIMER_RESET,
  MODAL_DIALOG,
  MODAL_CONTENT,
  MODAL_HEADER,
  MODAL_TITLE,
  MODAL_BODY,
} from '@/constants'
import { requestInterval, logout, commitMutation, getGetter } from '@/helpers'
import { REALITY_CHECK_MODAL } from '@/constants/modalName'
import Loader from '@/components/Loader'

const SESSION_CONTROL_MODAL_BUTTON_REF = 'sessionControlModalButtonRef'
const SESSION_CONTROL_MODAL_ID = 'sessionControlModal'
const leaveDelayTimeInitialValue = Digit.SIXTY
const initialStatValue = Digit.NOUGHT

export default {
  name: REALITY_CHECK_MODAL,
  components: {
    Loader,
  },
  data() {
    return {
      isGettingRealityCheck: false,
      leaveDelayRequest: null,
      leaveDelayTime: leaveDelayTimeInitialValue,
      winStat: initialStatValue,
      betStat: initialStatValue,
      rendersLeaveDelayTime: true,
    }
  },
  computed: {
    sessionControlModalButtonRef: () => SESSION_CONTROL_MODAL_BUTTON_REF,
    sessionControlModalId: () => SESSION_CONTROL_MODAL_ID,
    t() {
      return this.$createComponentTranslator(REALITY_CHECK_MODAL)
    },
    isLogged() {
      return getGetter([Module.USER, IS_LOGGED])
    },
    counter() {
      return this.rendersLeaveDelayTime
        ? `(${this.leaveDelayTime})`
        : EMPTY_STRING
    },
    classList() {
      return {
        modalDialog: MODAL_DIALOG,
        modalContent: MODAL_CONTENT,
        modalHeader: MODAL_HEADER,
        modalTitle: MODAL_TITLE,
        modalBody: [MODAL_BODY, 'csn-session-control-contrainer'],
      }
    },
  },
  methods: {
    triggerLeaveDelayCount() {
      this.leaveDelayRequest = requestInterval(
        this.countLeaveDelay,
        Digit.ONE_THOUSAND,
      )
    },
    async countLeaveDelay() {
      if (this.leaveDelayTime > Digit.NOUGHT) {
        this.leaveDelayTime = this.leaveDelayTime - Digit.ONE
        return
      }

      if (this.leaveDelayTime === Digit.NOUGHT && this.rendersLeaveDelayTime) {
        this.rendersLeaveDelayTime = false
        this.resetLeaveDelayTime()
        this.handleSessionControlLeave()
      }
    },
    resetLeaveDelayTime() {
      this.leaveDelayRequest && this.leaveDelayRequest.clear()
      this.leaveDelayTime = leaveDelayTimeInitialValue
    },
    async handleSessionControlConfirm() {
      this.$emit(EventType.CLOSE)

      await AuthApi.confirmReality()
      this.resetLeaveDelayTime()
      commitMutation([Module.MAIN, SET_IS_SESSION_CONTROL_PENDING], false)
      commitMutation([Module.MAIN, SET_NEEDS_TIMER_RESET], true)
    },
    async handleSessionControlLeave() {
      this.rendersLeaveDelayTime && (this.rendersLeaveDelayTime = false)
      this.$emit(EventType.CLOSE)

      if (this.isLogged) {
        await AuthApi.rejectReality()
        await logout()
      }
      commitMutation([Module.MAIN, SET_IS_SESSION_CONTROL_PENDING], false)
    },
  },
  watch: {
    isGettingRealityCheck(value, prevValue) {
      if (!value && prevValue) {
        this.triggerLeaveDelayCount()
      }
    },
  },
  async created() {
    try {
      this.isGettingRealityCheck = true
      const { data: realityCheckData } = await AuthApi.getRealityCheck()
      const { realityCheck, rejectCounterTime } = realityCheckData
      const { totalWin, totalBet } = realityCheck

      this.leaveDelayTime = Number(rejectCounterTime)
      this.winStat = totalWin
      this.betStat = totalBet
      this.isGettingRealityCheck = false
    } catch (e) {
      console.log(e)
    }
  },
}
</script>
